import classes from './References.module.css';
import { Fragment } from 'react';
import { SlideshowLightbox } from 'lightbox.js-react';
import Calculator from '../Pictures/calculator.PNG';
import ResponsiveApp from '../Pictures/React_web_App.png';
import Requalification1 from '../Pictures/Osvedceni o rekvalifikaci/Osvedceni o rekvalifikaci Ondrej Nekolny1.png';
import Requalification2 from '../Pictures/Osvedceni o rekvalifikaci/Osvedceni o rekvalifikaci Ondrej Nekolny2.png';
import CertResponsiveWebdesign from '../Pictures/Certifikat Responzivni webdesign.png';
import CertMySQL from '../Pictures/Certifikat MySQL_MariaDB databaze krok za krokem.png';
import CertJavaScript from '../Pictures/Udemy_JavaScript_Certificate.jpg';
import ReactCertificate from '../Pictures/React certificate jpg.jpg';
import JavaBasics from '../Pictures/Certifikát Základní konstrukce jazyka Java.png';
import JavaOOP from '../Pictures/Certifikát Objektově orientované programování v Javě.png';
import ReactEshop from '../Pictures/Eshop.png';

const References = () => {
  return (
    <Fragment>
      <section>
        <article>
          <h1>Reference</h1>
          <p>
            Toto jsou zatím moje dosavadní práce. Není pro mě problém vytvářet
            webové stránky s responzivním obsahem. Rovněž mám praktické pracovní
            zkušenosti s programováním v JavaScriptu a Reactu. Níže uvedená ukázka
            kalkulačky slouží pro ilustraci. Jednoduchou kalkulačku dokážu
            naprogramovat v Javě, Reactu i JavaScriptu.
          </p>
        </article>
        <SlideshowLightbox className={classes.reference_article}>
          <img src={Calculator} alt='Calculator' />
          <img src={ResponsiveApp} alt='ResponsiveApp' />
          <img src={ReactEshop} alt='React-eshop'/>
        </SlideshowLightbox>
          <a href='https://react-eshop-55cd6.web.app/'>Zde si můžete prohlédnout můj demo Eshop v Reactu.</a>
        <article>
          <h2>Osvědčení o rekvalifikaci</h2>
          <p>
            Moje Osvědčení o rekvalifikaci je nejdůležitějším dokumentem
            dokládajícím mojí dostatečnou odbornou způsobilost pro práci v oboru
            IT.
          </p>
        </article>
        <SlideshowLightbox className={classes.reference_article}>
          <img src={Requalification1} alt='RequalificationView1' />
          <img src={Requalification2} alt='RequalificationView2' />
        </SlideshowLightbox>
        <article>
          <h2>Certifikáty</h2>
          <p>
            V této části uvádím přehled svých certifikátů, které jsem získal za
            absolvování teoretických lekcí a praktických cvičení vzdělávacích
            kurzů u akreditované společnosti ITnetwork s.r.o. a Udemy.com.
          </p>
        </article>
        <SlideshowLightbox className={classes.reference_article_certificates}>
          <img src={JavaBasics} alt='JavaBasics' />
          <img src={JavaOOP} alt='JavaOOP' />
          <img src={CertJavaScript} alt='CertJavaScript' />
          <img src={ReactCertificate} alt='ReactCertificate' />
          <img src={CertResponsiveWebdesign} alt='CertResponsiveWebdesign' />
          <img src={CertMySQL} alt='CertMySQL' />
        </SlideshowLightbox>
      </section>
    </Fragment>
  );
};

export default References;
