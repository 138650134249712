import { useState, useRef } from 'react';
import classes from './Form.module.css';
import styles from './commonStyles.module.css';
import emailjs from '@emailjs/browser';
import { AnimatePresence, motion } from 'framer-motion';

const emailIsValid = (email) =>
  (email.indexOf('@') !== -1 && email[email.length - 3] === '.') ||
  email[email.length - 4] === '.';

const actualYearisValid = (year) => {
  return year === String(new Date().getFullYear());
};
const messageIsValid = (value) => value.trim().length >= 10;

const Form = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formInputsValidity, setFormInputsValidity] = useState({
    email: true,
    actualYear: true,
    message: true,
  });

  const emailInputRef = useRef();
  const actualYearRef = useRef();
  const [messageValue, setMessageValue] = useState('');

  const emailChangeHandler = () => {
    setFormInputsValidity({
      ...formInputsValidity,
      email: true,
    });
  };

  const actualYearChangeHandler = () => {
    setFormInputsValidity({
      ...formInputsValidity,
      actualYear: true,
    });
  };

  const messageChangeHandler = (event) => {
    setMessageValue(event.target.value);
    setFormInputsValidity({
      ...formInputsValidity,
      message: true,
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    let enteredEmail = emailInputRef.current.value;
    const enteredActualYear = actualYearRef.current.value;
    const enteredMessage = messageValue;

    // Validace inputů
    const enteredEmailIsValid = emailIsValid(enteredEmail);
    const enteredActualYearIsValid = actualYearisValid(enteredActualYear);
    const enteredMessageIsValid = messageIsValid(enteredMessage);

    // Validace formuláře
    setFormInputsValidity({
      email: enteredEmailIsValid,
      actualYear: enteredActualYearIsValid,
      message: enteredMessageIsValid,
    });

    const formIsValid =
      enteredEmailIsValid && enteredActualYearIsValid && enteredMessageIsValid;

    if (!formIsValid) {
      return;
    }

    emailjs.sendForm(
      'service_11g8lmi',
      'template_rd11i37',
      event.target,
      'H-xpjDw-QOK8y7tvc'
    );

    setFormSubmitted(true);

    // Resetování inputů
    emailInputRef.current.value = '';
    actualYearRef.current.value = '';
    setMessageValue('');

    setTimeout(() => {
      setFormSubmitted(false);
    }, 5000);

    // console.log({ enteredEmail, enteredActualYear, enteredMessage });
  };

  const emailValidityClass = formInputsValidity.email ? '' : classes.invalid;

  const actualYearValidityClass = formInputsValidity.actualYear
    ? ''
    : classes.invalid;

  const messageValidityClass = formInputsValidity.message
    ? ''
    : classes.invalid;

  return (
    <section className={classes.center}>
      <form onSubmit={submitHandler}>
        <AnimatePresence>
          {formSubmitted && (
            <motion.strong
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              className={classes.successInfo}
            >
              Zpráva byla úspěšně odeslána!
            </motion.strong>
          )}
        </AnimatePresence>
        <div>
          <label htmlFor='email'>Váš email:</label>
          <input
            type='email'
            name='email_from'
            placeholder='Zadejte Váš email'
            className={emailValidityClass}
            ref={emailInputRef}
            onChange={emailChangeHandler}
          />
          {!formInputsValidity.email && (
            <small>Prosím vyplňte správný email.</small>
          )}
        </div>
        <div>
          <label htmlFor='year'>Aktuální rok (kontrola proti spam):</label>
          <input
            type='text'
            min='1900'
            max='2099'
            step='1'
            placeholder='Zadejte aktuální rok'
            className={actualYearValidityClass}
            ref={actualYearRef}
            onChange={actualYearChangeHandler}
          />
          {!formInputsValidity.actualYear && (
            <small>Prosím vyplňte správný aktuální rok.</small>
          )}
        </div>
        <br />
        <div>
          <label htmlFor='message' name='message'>
            Vaše zpráva:
          </label>
          <textarea
            name='message'
            rows='10'
            placeholder='Zadejte zprávu dlouhou alespoň 10 znaků'
            className={messageValidityClass}
            value={messageValue}
            onChange={messageChangeHandler}
          />
          {!formInputsValidity.message && (
            <small>Prosím napište zprávu dlouhou alespoň 10 znaků.</small>
          )}
        </div>
        <div className={classes.btnCenter}>
          <motion.button
            whileHover={{ scale: 1.15 }}
            transition={{ type: 'spring', stiffness: 500, mass: 100 }}
            type='submit'
            className={styles.btnReferrence}
          >
            Odeslat
          </motion.button>
        </div>
      </form>
    </section>
  );
};

export default Form;
