import classes from './MainNavigation.module.css';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

const MyNavLink = (props) => {
  return (
    <li>
      <NavLink
        to={props.toRef}
        onClick={props.onSelect}
      >
        {props.label}
      </NavLink>
      {props.isSelected && (
        <motion.div
          layoutId='nav_a_underline'
          className={classes.nav_a_underline}
        />
      )}
    </li>
  );
};

export default MyNavLink;
