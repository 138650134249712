import classes from "./MainNavigation.module.css";
import styles from "./commonStyles.module.css";
import { motion } from "framer-motion";

import MyNavLink from "./MyNavLink";
import { useState } from "react";

const MainNavigation = () => {
  const [selectedType, setSelectedType] = useState(window.location.pathname);

  const handleSelectType = (newType) => {
    setSelectedType(newType);
  };

  return (
    <header>
      <nav>
        <ul className={classes.logo}>
          <li>
            <h2>
              Ondřej<span>Nekolný</span>
            </h2>
            <h5>Programátor webových aplikací</h5>
          </li>
        </ul>
        <ul className={classes.navigation}>
          <MyNavLink
            toRef="/"
            label="Domů"
            isSelected={selectedType === "/"}
            onSelect={() => handleSelectType("/")}
          />
          <MyNavLink
            toRef="about_me"
            label="O mně"
            isSelected={selectedType === "/about_me"}
            onSelect={() => handleSelectType("/about_me")}
          />
          <MyNavLink
            toRef="skills"
            label="Dovednosti"
            isSelected={selectedType === "/skills"}
            onSelect={() => handleSelectType("/skills")}
          />
          <MyNavLink
            toRef="references"
            label="Reference"
            isSelected={selectedType === "/references"}
            onSelect={() => handleSelectType("/references")}
          />
          <li>
            <motion.a
              whileHover={{ scale: 1.15 }}
              transition={{ type: "spring", stiffness: 500, mass: 100 }}
              href="contact"
              className={styles.btnReferrence}
            >
              Kontakt
            </motion.a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
