import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const AboutMe = () => {
  return (
    <Fragment>
      <section>
        <h1>O mně</h1>
        <p>
          Již od základní školy, kdy jsem se poprvé setkal s prací na počítači,
          mě výpočetní technologie fascinovala a přál jsem si na její studium
          zaměřit i na střední škole, ať už bych studoval jakýkoli obor.
        </p>
        <p>
          Při hledání střední školy jsem hledal obor, ve kterém bych mohl
          uplatnit svůj zájem o výpočetní techniku. Nicméně v té době se u mě
          také projevil zájem o studium chemie. Moji učitelé mě ve studiu chemie
          podporovali s tím, že i v tomto oboru lze uplatnit zájem o neustále se
          rozvíjející výpočetní technologii. Obor Výpočetní technologie v chemii
          bohužel v roce mého nástupu na střední školu nebyl pro nedostatek
          zájmu studentů otevřen, a proto jsem nakonec vystudoval obor Chemická
          technologie.
        </p>
        <p>
          Abych zvýšil svoje šance na pracovní uplatnění, rozhodl jsem se pro
          studium farmacie na vyšší odborné škole. Toto studium jsem s úspěšně
          dokončil v roce 2014 a začal jsem pracovat jako diplomovaný
          farmaceutický asistent. Nejprve ve farmaceutické laboratoři, kde jsem
          připravoval individuálně připravované léčivé přípravky, jako jsou
          masti, roztoky, šampony, suspenze, kapky, sirupy apod. Tato práce mě
          naučila
          <strong>
            {' '}
            analytickému myšlení, pozornosti, systematičnosti a schopnosti
            myslet na práci tady a teď.
          </strong>
        </p>
        <p>
          Po dvou a půl letech v laboratoři nastal čas rozvíjet se dál a tak
          jsem se rozhodl vyměnit prostředí farmaceutické laboratoře za
          klasickou lékárnu. Prošel jsem několik lékáren u různých společností.
          Naučil jsem se
          <strong> efektivně komunikovat se zákazníkem</strong> a výrazně jsem
          zlepšil i svoji schopnost používat
          <strong> anglický jazyk</strong>. Moje úroveň znalosti anglického
          jazyka odpovídá úrovni B2 nebo vyšší.
        </p>
        <p>
          V září 2021 jsem se také stal členem
          <strong> Mensy ČR.</strong> Členství v této organizaci si velmi cením.
        </p>
        <p>
          Od května 2024 jsem také <strong>dobrovolným dárcem krve</strong>.
          Považuji to za užitečné pro ty, kteří to potřebují.
        </p>
        <p>
          Abych mohl ovšem postoupit ve svém životě dál, uvědomil jsem si, že
          potřebuji růst a vzdělávat se. Jen díky práci na sobě se cítím
          spokojený. Proto jsem se rozhodl vrátit zpět k tomu, co mě dříve
          zajímalo - k programování. U společnosti ITnetwork jsem se začal
          věnovat studiu jazyka Java a časem jsem zjistil, že mě to baví. Tak
          jsem se rozhodl pro čtyřměsíční rekvalifikační kurz. Zakončil jsem ho
          úspěšným složením rekvalifikační zkoušky dne 30.1.2022. Nicméně tím
          moje skutečné studium teprve začalo. Od té doby se každý den věnuji
          studiím na Udemy.com. Velmi mě těší, že se v IT oboru lze neustále
          setkávat s novými technologiemi, přístupy i lidmi a díky tomu je vždy
          možné najít něco nového, co umožňuje zvýšit kvalifikaci a
          posunout se dál mě i druhým.
        </p>
        <p>
          Mojí specializací je <strong>React</strong>,{' '}
          <strong>JavaScript</strong> a <strong>Java</strong>. V Javě umím dobře
          základy, ty jsou samozřejmostí a také objektově orientované
          programování. Další možnosti programování v Javě studuji v kurzu na
          Udemy.com, abych svoje znalosti v Javě prohloubil a stal se postupně
          expertem na Javu.
        </p>
        <p>
          <Link to="/">Zpět na hlavní stránku</Link>
        </p>
      </section>
    </Fragment>
  );
};

export default AboutMe;
